import React, { useCallback, useMemo, useRef, useState } from 'react'
import GridTable from '@/components/table/GridTable'
import {
  GET_DELIVERY_AREAS,
  PAGE_SIZE_ON_DELIVERY_AREAS,
  useExportExcelOnDeliveryAreas,
  useGetCitiesOnDeliveryAreas,
  useGetDeliveryAreas,
  useUpdateDeliveryArea,
  useUploadExcelOnDeliveryAreas,
} from '@/services/deliveryAreas/useDeliveryAreasQueries'
import { VStack } from '@/components/content/Stack'
import { FormProvider, useForm } from 'react-hook-form'
import { DeliveryAreaItem, GetDeliveryAreasReq, UpdateDeliveryAreaReq } from '@/services/deliveryAreas/types'
import DeliveryAreasForm, { INIT_FORM } from '@/pages/master/deliveryAreas/components/DeliveryAreasForm'
import { AgGridReact } from 'ag-grid-react'
import { flushSync } from 'react-dom'
import { useParsedQuery } from '@/hooks/useParsedQuery'
import { ColDef } from 'ag-grid-community'
import MAlert from '@/utils/MAlert'
import MToast from '@/utils/MToast'
import MConfirm from '@/utils/MConfirm'
import { queryClient } from '@/utils/queryClient'
import useModal from '@/hooks/useModal'
import DeliveryAreasAddModal from '@/pages/master/deliveryAreas/components/DeliveryAreasAddModal'
import { Utils } from '@/utils/Utils'
import { DeliveryAreasUtils } from '@/pages/master/deliveryAreas/components/DeliveryAreasUtils'
import { ValueSetterParams } from 'ag-grid-community/dist/lib/entities/colDef'
import { omitBy } from 'lodash-es'

function DeliveryAreas() {
  const { parsedQs, replaceQuery } = useParsedQuery<GetDeliveryAreasReq>()
  const form = useForm<GetDeliveryAreasReq>({ defaultValues: { ...INIT_FORM, ...parsedQs } })

  const [queryKey, setQueryKey] = useState(form.getValues())
  const [currentPage, setCurrentPage] = useState(0)

  const { data, isFetching, refetch } = useGetDeliveryAreas({ ...queryKey, page: currentPage })
  const updateDeliveryArea = useUpdateDeliveryArea()

  const { onExportData } = useExportExcelOnDeliveryAreas(queryKey)
  const { onUploadData } = useUploadExcelOnDeliveryAreas()
  const { data: cities } = useGetCitiesOnDeliveryAreas()
  const addModal = useModal()

  const $grid = useRef<AgGridReact>(null)
  const columnDefs = useMemo<ColDef<DeliveryAreaItem>[]>(() => {
    const onCheckValid = ({ column, newValue, data }: ValueSetterParams) => {
      const trimmed = typeof newValue === 'string' ? newValue.replace(/ /g, '') : newValue

      const colId = column.getColId() as keyof DeliveryAreaItem
      const isValid = DeliveryAreasUtils.validateFields({ ...data, [colId]: trimmed })
      if (!isValid) return false

      data[colId] = trimmed as never
      return true
    }

    return [
      { field: 'zipcode', headerName: '우편번호', width: 110 },
      {
        field: 'city',
        headerName: '시구분',
        editable: true,
        width: 180,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: { values: cities },
      },
      { field: 'district', headerName: '구구분', editable: true, width: 180, valueSetter: onCheckValid },
      { field: 'town', headerName: '읍면동', editable: true, width: 180, valueSetter: onCheckValid },
      {
        field: 'deliveryLeadDay',
        headerName: '배송리드타임',
        editable: true,
        width: 130,
        cellDataType: 'number',
        valueSetter: onCheckValid,
      },
      { field: 'remoteArea', headerName: '도서산간여부', editable: true, type: 'checkbox', width: 130 },
      { field: 'createdAt', headerName: '생성일자', cellDataType: 'datetime', width: 150 },
      { field: 'createdBy', headerName: '생성자', width: 200 },
      { field: 'lastModifiedAt', headerName: '수정일자', cellDataType: 'datetime', width: 150 },
      { field: 'lastModifiedBy', headerName: '수정자', width: 200 },
    ]
  }, [cities])

  const handleSubmit = useCallback(async (formData: GetDeliveryAreasReq) => {
    if (!formData.zipcode && !formData.city) {
      return MToast.error('검색을 위해 우편번호 혹은 시구분 둘 중 하나를 입력 및 선택해주세요.')
    }

    $grid.current?.api?.deselectAll()
    queryClient.resetQueries({ queryKey: GET_DELIVERY_AREAS })

    const query = omitBy(formData, (value) => value === '') as GetDeliveryAreasReq
    flushSync(() => {
      setQueryKey(query)
      setCurrentPage(0)
    })

    await refetch()
    replaceQuery(query)
  }, [])

  const onChangePage = useCallback((page: number) => {
    $grid.current?.api?.deselectAll()

    flushSync(() => setCurrentPage(page))
    refetch()
  }, [])

  const handleSave = useCallback(() => {
    const selectedRows: UpdateDeliveryAreaReq[] = $grid.current?.api?.getSelectedRows() || []
    if (!selectedRows.length) return MToast.error('저장할 데이터가 선택되지 않았습니다.')

    updateDeliveryArea
      .mutateAsync(selectedRows.map((row) => ({ ...row, district: row.district || null })))
      .then(async () => {
        MToast.show(`${selectedRows.length}개 수정 완료`)

        $grid.current?.api?.deselectAll()
        await refetch()
      })
      .catch((e) => {
        MAlert.show(Utils.getApiErrMessage(e, '저장에 실패했습니다.'))
      })
  }, [])

  const onDeleteRows = useCallback((deleted: DeliveryAreaItem[]) => {
    if (!deleted.length) return MToast.error('삭제할 데이터가 선택되지 않았습니다.')

    updateDeliveryArea
      .mutateAsync(deleted.map((row) => ({ ...row, deleted: true })))
      .then(async () => {
        MToast.show(`${deleted.length}개 삭제 완료`)

        $grid.current?.api?.deselectAll()
        await refetch()
      })
      .catch((e) => {
        MAlert.show(Utils.getApiErrMessage(e, '삭제에 실패했습니다.'))
      })
  }, [])

  const handleUploadData = useCallback(
    (file: File) => {
      MConfirm.show('우편번호 권역에 대해 신규 추가 및 변경 업데이트를 진행합니다.\n진행하시겠습니까?', {
        onConfirm: () => {
          onUploadData(file)
            .then((response) => {
              const uploadedCount = response.data || 0
              MToast.show(`${uploadedCount}개 정상 업로드 되었습니다.`)
              handleSubmit(queryKey)
            })
            .catch((e) => {
              MAlert.show(Utils.getApiErrMessage(e, '업로드에 실패했습니다.'))
            })
        },
      })
    },
    [queryKey],
  )

  const handleExportData = useCallback(() => {
    if (!queryKey.zipcode && !queryKey.city) {
      return MToast.error("데이터 조회 완료 후, 다운로드가 가능합니다. '우편번호' 및 '시구분' 값을 설정하여 조회 후, 다운로드 진행해주세요.")
    }

    onExportData()
  }, [onExportData, queryKey])

  return (
    <>
      <FormProvider {...form}>
        <VStack full gap={20}>
          <DeliveryAreasForm isLoading={isFetching} onSave={handleSave} onSubmit={handleSubmit} />
          <GridTable<DeliveryAreaItem>
            rowSelectable
            ref={$grid}
            isLoading={isFetching}
            countPerPage={PAGE_SIZE_ON_DELIVERY_AREAS}
            totalCount={data?.totalElements}
            totalPages={data?.totalPages}
            currentPage={data?.currentPage}
            rowData={data?.list || []}
            columnDefs={columnDefs}
            onChangePage={onChangePage}
            onExportData={handleExportData}
            onUploadData={handleUploadData}
            onAddRow={addModal.onOpenModal}
            onDeleteRows={onDeleteRows}
            useColumnFilter
          />
        </VStack>
      </FormProvider>

      {addModal.open && <DeliveryAreasAddModal open onRefresh={() => handleSubmit(queryKey)} onClose={addModal.onCloseModal} />}
    </>
  )
}

export default DeliveryAreas
